import React from "react";
import Header from "../../components/profiler/header";
import UserTag from "../../components/profiler/userTag";
import Progress from "../../components/profiler/progress";
import Smart from "../../assets/svg/logo_solides.svg";

import "./index.scss";

function TryAgain({ showTryAgain, message, tryAgainFunction }) {
  return (
    showTryAgain && (
      <div className="try-again-page">
        <Header>
          <img className="brand" src={Smart} alt="logo solides" />
          <UserTag />
          <Progress step={3}>
            <p>Passo 3 de 3</p>
          </Progress>
        </Header>
        <div className="try-again">
          <p>{message}</p>
          <button onClick={tryAgainFunction}>Tentar novamente</button>
        </div>
      </div>
    )
  );
}
export default TryAgain;
