import React, { Fragment, useState, useEffect } from "react";
import Container from "../../components/profiler/container";
import Row from "../../components/profiler/row";
import Welcome from "../../components/profiler/welcome";
import FormProfilerRegister from "../../components/forms/profilerRegisterForm";
import InitialPage from "../../components/initialPage";
import Header from "../../components/profiler/header";
import Section from "../../components/profiler/section";
import Smart from "../../assets/images/solides.png";
import StepOne from "../../components/profiler/steps/stepOne";
import StepTwo from "../../components/profiler/steps/stepTwo";
import Loading from "../../components/loading";
import Result from "./resultProfilerNoAuth";
import Moment from "moment";
import { notification } from "antd";
import "./index.scss";

import * as passportsService from "../../state/profiler/services";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import TryAgain from "../../components/tryAgain";

function Profiler({
  passportGetByToken,
  passportPostByToken,
  saveProfilerResult,
  history
}) {
  /* eslint-disable */
  const url = window.location.href.split("/");
  const urlToken = url[4].split("?");
  const sendToken = urlToken[0] ? urlToken[0] : url[4];
  const [step, setStep] = useState(0);
  const [registerData, setRegisterData] = useState({});
  const [iAmChosenAdjectives, setArrayAdjectives] = useState([]);
  const [, setArrayPerception] = useState([]);
  const [blockStep, setBlockStep] = useState(false);
  const [linkGeneric, setLinkGeneric] = useState();
  const [getPassport, setGetPassport] = useState([]);
  const [, setCanJumpStepFinal] = useState(false);
  const [canGetResultProfiler, canSetResultProfiler] = useState(false);
  const [profiler, setProfiler] = useState({});
  const [infoUser, setInfoUser] = useState({});
  const [testStartTime, setTestStartTime] = useState({});
  const [avaliablesPositions, setAvaliablesPositions] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [photoBase64, setPhotoBase64] = useState("");
  const [dadosProfiler, setDadosProfiler] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [tryAgainMessage, setTryAgainMessage] = useState("");
  const [tryAgainFunction, setTryAgainFunction] = useState(() => {});

 /* eslint-enable */
  const nextStep = e => {
    switch (step) {
      case 0:
        if (e !== undefined) {
          e.preventDefault();
        }
        break;
      case 1:
        if (e !== undefined) {
          e.preventDefault();
        }
        break;
      default:
        break;
    }
    setStep(step + 1);
  };

  async function fetchGetPassportByToken() {
    await passportGetByToken(sendToken)
      .then(r => {
        setGetPassport(r.data);
        setAvaliablesPositions(r.data.positions);
        if (r.data.result !== undefined) {
          setProfiler(r.data.result);
          setInfoUser(r.data);
          setStep(4);
          setLinkGeneric(false);
          setShowResult(true);
        } else {
          setLinkGeneric(true);
          setShowResult(false);
        }
      })
      .catch(err => {
        if (err) {
          notification.error({
            message: "Erro referente ao passaporte",
            description: `${err}`
          });
        } else {
          history.push("/peopleAnalytics");
        }
      });
  }

   /* eslint-disable */
  useEffect(() => {
    fetchGetPassportByToken();
  }, []);
  /* eslint-enable */
  useEffect(() => {
    if (registerData !== {}) {
      setTestStartTime(Moment(Date.now()).format("YYYY-MM-DD H:mm:ss"));
    }
  }, [registerData]);

  const _handleClick = obj3 => {
    const obj1 = {
      iAmChosenAdjectives,
      testStartTime,
      testEndTime: Moment(Date.now()).format("YYYY-MM-DD H:mm:ss")
    };
   
    const result = {
      ...obj1,
      shouldBeChosenAdjectives: obj3,
      picture: photoBase64
    };
    setDadosProfiler(result);
    setBlockStep(true);
    setStep(3);
  };

  const sendProfiler = (formData, contactSLA) =>{
    setShowLoading(true);
    setShowTryAgain(false);
    passportPostByToken(sendToken, {...dadosProfiler,...formData})
      .then(res => {
        if (res.status === 200 && res.data.result !== undefined) {
          saveProfilerResult(contactSLA, res.data.result);
          canSetResultProfiler(true);
          setProfiler(res.data.result);
          setInfoUser(res.data);
          return;
        } 
        if(res.status === 200 && res.data.credits && res.data.credits.applicantCredits === 0 && res.data.credits.employeeCredits === 0){
          throw new Error("Ops!\nParece que os créditos acabaram. Por favor mostre essa mensagem para um dos responsáveis pelo evento.");
        }
        throw new Error("Ops!\n Parece que você está com problema para se conectar com nossos servidores.")
      })
      .catch(err => {
        canSetResultProfiler(false);
        setShowLoading(false);
        setShowTryAgain(true);
        setTryAgainFunction(() => () => sendProfiler(formData, contactSLA));
        notification.error({
          message: "Erro referente ao token",
          description: `${err}`
        });
        if(err.message === 'Network Error') {
          setTryAgainMessage('Ops! Parece que você está com problemas para se conectar à internet. Verifique sua conexão e tente novamente.');
          return;
        }

        if(err.response && err.response.status >= 500) {
          setTryAgainMessage('Ops! Parece que houve um erro ao se conectar com nosso servidor.');
          return;
        }
        setTryAgainMessage(err.message);
      });
  }
   /* eslint-disable */
  useEffect(() => {
    if (canGetResultProfiler === true) {
      nextStep();
    }
  }, [canGetResultProfiler]);
 /* eslint-enable */
  const _wizardStep = () => {
    switch (step) {
      case 0:
        if (linkGeneric === true) {
          return (
            <Fragment>
              {step === 0 && (
                <div className="profiler-container home">
                  <Header>
                    <img className="logoInitial" src={Smart} alt="smart" />
                  </Header>
                  <Section nameClass={"welcome"}>
                    <Container>
                      <Row>
                        <Welcome getPassport={getPassport} />
                      </Row>
                    </Container>
                  </Section>
                  <Section nameClass={"form-profile"}>
                    <Container>
                      <Row>
                        <InitialPage 
                          wichStep={nextStep}
                        />
                      </Row>
                    </Container>
                    <div className="profiler-welcome">
                    <div className="message">
                        <p className="message-welcome">Reserve 10 minutos para preenchê-lo sem interrupções, com sinceridade.
                        Não há respostas corretas.</p>
                    </div>
                </div>
                  </Section>
                </div>
              )}
            </Fragment>
          );
        } else {
          return null;
        }
      case 1:
        return (
          <div className="profiler-container">
            {step === 1 && (
              <StepOne
                registerData={registerData}
                photoBase64={photoBase64}
                dataFallbackArr={value => setArrayAdjectives(value)}
                dataFallbackJumpToStepTwo={value => {
                  if (!value) {
                    setStep(2);
                  }
                }}
              />
            )}
          </div>
        );
      case 2:
        return (
          <div className="profiler-container">
            {step === 2 && (
              <StepTwo
                registerData={registerData}
                photoBase64={photoBase64}
                dataFallbackArr={value => {
                  setArrayPerception(value);
                }}
                dataFallBackFinish={value => {
                  setCanJumpStepFinal(value);
                }}
                handleClick={_handleClick}
                blockStep={blockStep}
              />
            )}
          </div>
        );
      case 3:
        return (
          <>
          { !showTryAgain && (
              <FormProfilerRegister
                getPassport={getPassport}
                getPositions={avaliablesPositions}
                wichStep={sendProfiler}
                registerFallback={(value) => setRegisterData(value)}
              />
            )}
          <TryAgain
            message={tryAgainMessage}
            tryAgainFunction={tryAgainFunction}
            showTryAgain={showTryAgain}
          />
          <Loading 
            loadingMessage="Enviando relatório..."
            showLoading={showLoading}/>
          </>
        );
      case 4:
        return (
          <Result
            profiler={profiler}
            canShowAll={showResult}
            isFillLink={linkGeneric}
            infoUser={infoUser}
          />
        );
      default:
        break;
    }
  };

  return _wizardStep();
}

const mapStateToProps = state => ({
  // modal: state.user.modal
});

const mapDispatchToProps = {
  passportGetByToken: passportsService.passportGetByToken,
  passportPostByToken: passportsService.passportPostByToken,
  saveProfilerResult: passportsService.saveProfilerResult
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Profiler);
