import axios from "axios";

export const passportGetByToken = async (token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_GESTAO_BASE_API_URL}/v2/passports/${token}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const passportPostByToken = async (token, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_GESTAO_BASE_API_URL}/v2/passports/${token}/answer`,
        data
      )
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveProfilerResult = async (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_PROFILER_MARTECH_BASE_API_URL}/v1/profilerResult`,
        body
      )
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
