// import * as actions from "./actions";
import * as repository from "./repository";
// import { filter } from "rsvp";

export const passportGetByToken = (token) => async (dispatch) => {
  return repository.passportGetByToken(token);
};

export const passportPostByToken = (token, data) => async (dispatch) => {
  return repository.passportPostByToken(token, data);
};

export const resultManager = (id) => async (dispatch) => {
  return repository.passportPostByToken(id);
};

export const saveProfilerResult =
  (contactSLA, gestaoData) => async (dispatch) => {
    const profile = gestaoData.chart_data.profiler;
    const body = {
      primaryProfile: profile[0],
      secondaryProfile: profile[1],
      tertiaryProfile: profile[2],
      quaternaryProfile: profile[3],
      ...contactSLA,
    };
    return repository.saveProfilerResult(body);
  };
