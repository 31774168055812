import React, { useEffect } from "react";
import "./index.scss";
import Header from "../../../components/profiler/header";
import UserTag from "../../../components/profiler/userTag";
import Progress from "../../../components/profiler/progress";
import Smart from "../../../assets/svg/logo_solides.svg";
import HubspotFormContainer from "../HubspotFormContainer";

function FormProfilerRegister({ wichStep, registerFallback }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmitForm = async (hubspotSubmissionValues) => {
    const formData = {
      name: hubspotSubmissionValues.firstname,
      email: hubspotSubmissionValues.email,
      gender:
        hubspotSubmissionValues.genero === "non-binary"
          ? "male"
          : hubspotSubmissionValues.genero,
      idNumber: hubspotSubmissionValues.cpf,
      company: hubspotSubmissionValues.company,
      phone: hubspotSubmissionValues.phone,
    };

    const contactSLA = {
      cargo: hubspotSubmissionValues.cargo_rh,
      tamanhoEmpresa: hubspotSubmissionValues.tamanho_de_empresa,
      eventId: hubspotSubmissionValues.token_evento,
      email: hubspotSubmissionValues.email,
      gender: hubspotSubmissionValues.genero
    }
    
    registerFallback(formData);
    wichStep(formData, contactSLA);
  };
  /* eslint-disable */
  return (
    <div className="registerProfiler">
      <Header>
        <img className="brand" src={Smart} alt="logo solides" />
        <UserTag />
        <Progress step={3}>
          <p>Passo 3 de 3</p>
        </Progress>
      </Header>
      <div className="formHeader">
        <h1> Já temos seu resultado! </h1>
        <p>
          Preencha o formulário abaixo para receber o relatório por email
          gratuitamente.
        </p>
      </div>
      <HubspotFormContainer handleSubmitForm={handleSubmitForm} />
    </div>
  );
  /* eslint-enable */
}

export default FormProfilerRegister;
