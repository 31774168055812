import React from "react";
import "./index.scss";

const Welcome = ({ getPassport }) => {
    return (
        <div className="profiler-welcome">
            <div className="message">
                <h3>{getPassport.name ? getPassport.name + "," : ""}</h3>
                <h1 className="message-welcome">
                    Profiler é um questionário preciso para identificar seu
                    perfil comportamental. 
                </h1>
            </div>
        </div>
    );
};

export default Welcome;
