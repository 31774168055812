import React from "react";
import "./index.scss";

const getCssClass = function (step) {
    switch (step) {
        case 1:
            return "progress-intern-first";
        case 2:
            return "progress-intern-second";
        case 3:
            return "progress-intern-third";
        default:
            return "";
    }
}

const Progress = ({ children, step }) => {
    return (
        <div className="profiler-progress">
            <div className="step">
                {children}
                <div className="progress">
                    <div className={`progress-intern ${getCssClass(step)}`}></div>
                </div>
            </div>
        </div>
    );
};

export default Progress;
