import React, { useEffect, useState } from "react";
import "./index.scss";

function HubspotFormContainer({ handleSubmitForm }) {
  /* eslint-disable */
  const [loading, setLoading] = useState(true);
  const [loadFailed, setLoadFailed] = useState(false);

  const url = window.location.href.split("/");
  const urlToken = url[4].split("?");
  const tokenValue = urlToken[0] ? urlToken[0] : url[4];
  useEffect(() => {
    loadForm();
  }, []);
  /* eslint-enable */

  const loadForm = () => {
    setLoadFailed(false);
    setLoading(true);

    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    script.async = true;

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "22604900",
          region: "na1",
          formId: process.env.REACT_APP_HUBSPOT_FORM_ID,
          target: "#hubspot-form",
          onFormSubmitted: async (form, data) => {
            await handleSubmitForm(data.submissionValues);
          },
          onFormReady: () => {
            setLoading(false);
            const tokenInput = document.getElementsByName("token_evento")[0];
            tokenInput.value = tokenValue;
            tokenInput.dispatchEvent(
              new Event("input", { bubbles: true, cancelable: true })
            );
          },
        });
      } else {
        setLoadFailed(true);
        setLoading(false);
      }
    };

    script.onerror = () => {
      setLoadFailed(() => true);
      setLoading(() => false);
    };

    document.body.appendChild(script);

    // Timeout to handle load failure
    setTimeout(() => {
      setLoading((previusValue) => {
        if (previusValue) {
          setLoadFailed(true);
        }
        return false;
      });
    }, 6000);
  };

  return (
    <div id="hubspot-form">
      {loadFailed && (
        <div className="internet-error">
          <p>
            Ops! Parece que você está tendo problemas pra se conectar a
            internet.
          </p>
          <p>Verifique sua conexão e tente novamente.</p>
          <button onClick={loadForm}>Tentar novamente</button>
        </div>
      )}
      {!loadFailed && (
        <div className="loading-form">
          <div id="loading-spinner"></div>
        </div>
      )}
    </div>
  );
}

export default HubspotFormContainer;
