import React from "react";
import "./index.scss";
import loadIcon from "../../assets/svg/loading.svg"


const Loading = ({showLoading, loadingMessage}) => {
    

    return (
        showLoading && 
        <div className="loading">
          <div className="loadingContent">
            <img className="imageIcon" src={loadIcon} alt="Loading icon" />
            <p className="loadingText" >{loadingMessage}</p>
          </div>
      </div>
  );
}
    
  export default Loading;
