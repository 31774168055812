import React from "react";
import Button from "../button";
import "./index.scss";

const InitialPage = ({wichStep}) => {
  return (
    <div className="profiler-start">
        <Button
          text="Iniciar"
          onClick={wichStep}
          type="primary"
        />
    </div>
);
  }
  
export default InitialPage;